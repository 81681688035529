<template>
  <ValidationObserver
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <BModal
      id="modal-add-bank-accounts"
      :title="$t('bankAccount.addBankAccount')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{ cancel }">
        <BButton
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('bankAccount.cancel') }}
        </BButton>

        <BButton
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="invalid"
          @click="handleAdd"
        >
          <div class="d-flex-center px-25">
            {{ $t('bankAccount.add') }}
          </div>
        </BButton>
      </template>

      <div>
        <!-- ANCHOR bankId -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.bankId')"
          rules="required"
        >
          <BFormGroup label-for="bankId">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.bankId') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <v-select
              id="bankId"
              v-model="bankAccountToAdd.bankId"
              :state="getValidationState(validationContext) === false ? false : null"
              label="code"
              :options="bankListOptions"
              :filterable="false"
              searchable
              :reduce="val => val.id"
              :placeholder="$t('bankAccount.ph.bankId')"
              :clearable="false"
              @open="openBankListHandle"
              @search="searchBankListHandle"
              @option:selected="selectedBank($event)"
            >
              <template #option="data">
                <div class="d-flex-center justify-content-start">
                  <BImg
                    :src="data.logo"
                    width="50"
                  />
                  <span>
                    {{ `${data.shortName} (${data.code}) - ${data.name})` }}
                  </span>
                </div>
              </template>

              <template #selected-option="data">
                <div class="d-flex-center justify-content-start">
                  <BImg
                    :src="data.logo"
                    width="50"
                  />
                  <span>
                    {{ `${data.shortName} (${data.code}) - ${data.name})` }}
                  </span>
                </div>
              </template>

              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>

        <!-- ANCHOR bankAccountNo -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.bankAccountNo')"
          rules="required"
        >
          <BFormGroup label-for="bankAccountNo">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.bankAccountNo') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="bankAccountNo"
              v-model.trim="bankAccountToAdd.bankAccountNo"
              :state="getValidationState(validationContext) === false ? false : null"
              :placeholder="$t('bankAccount.ph.bankAccountNo')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>

        <!-- ANCHOR bankAccountName -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.bankAccountName')"
          rules="required"
        >
          <BFormGroup label-for="bankAccountName">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.bankAccountName') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="bankAccountName"
              v-model="bankAccountToAdd.bankAccountName"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('bankAccount.ph.bankAccountName')"
              :disabled="bankSelected.isAuto"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>

        <!-- ANCHOR Branch -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.branch')"
          rules="required"
        >
          <BFormGroup label-for="branch">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.branch') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="branch"
              v-model="bankAccountToAdd.branch"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('bankAccount.ph.branch')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <template v-if="bankAccountToAdd.isAuto">
        <!-- ANCHOR identificationNumber -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.identificationNumber')"
          rules="required"
        >
          <BFormGroup label-for="identificationNumber">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.identificationNumber') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="identificationNumber"
              v-model="bankAccountToAdd.identificationNumber"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('bankAccount.ph.identificationNumber')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>

        <!-- ANCHOR phoneNumber -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.phoneNumber')"
          rules="required"
        >
          <BFormGroup label-for="phoneNumber">
            <template #label>
              <div class="text-nowrap">
                {{ $t('bankAccount.phoneNumber') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="phoneNumber"
              v-model="bankAccountToAdd.phoneNumber"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('bankAccount.ph.bankAccountName')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </template>

      <div v-if="!bankAccountToAdd.isAuto">
        <!-- ANCHOR isDefault -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.isDefault')"
          rules="required"
        >
          <BFormGroup label-for="isDefault">
            <BFormCheckbox
              id="isDefault"
              v-model="bankAccountToAdd.isDefault"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              switch
              :placeholder="$t('bankAccount.isDefault')"
            >
              <div class="text-nowrap">
                {{ $t('bankAccount.isDefault') }}
              </div>
            </BFormCheckbox>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <div v-if="bankSelected && bankSelected.isAuto">
        <!-- ANCHOR isAuto -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('bankAccount.isDefault')"
          rules="required"
        >
          <BFormGroup label-for="isAuto">
            <BFormCheckbox
              id="isAuto"
              v-model="bankAccountToAdd.isAuto"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              switch
              :placeholder="$t('bankAccount.isAuto')"
            >
              <div class="text-nowrap">
                {{ $t('bankAccount.isAuto') }}
              </div>
            </BFormCheckbox>
            <small class="text-danger">
              {{ $t('modal.setAutoBank', { fee: formatCurrency(bankSelected.sepayFeeConfig) }) }}
            </small>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>
    </BModal>
  </ValidationObserver>
</template>
<script>
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BModal,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import store from '@/store'
import { apiBankAccount } from '@/api'

import {
  formatCurrency,
} from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BImg,
  },
  props: {
    agencyId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()
    const blankBankAccount = {
      bankId: '',
      branch: '',
      bankAccountNo: '',
      bankAccountName: '',
      identificationNumber: '',
      phoneNumber: '',
      label: '',
      isDefault: false,
      isAuto: false,
    }

    const bankAccountToAdd = ref(cloneDeep(blankBankAccount))
    const bankListOptions = ref()
    const bankSelected = ref({ isAuto: false })
    const loading = ref(false)

    const resetBankAccountToAdd = () => {
      bankAccountToAdd.value = cloneDeep(blankBankAccount)
    }

    const { refFormObserver, getValidationState } = formValidation(resetBankAccountToAdd)

    function showHandle() {
      resetBankAccountToAdd()
    }

    function selectedBank(bank) {
      bankSelected.value = bank
    }

    async function handleAdd() {
      this.$bvModal.show('modal-api-loading')
      loading.value = true
      try {
        const req = bankAccountToAdd.value
        if (req.isAuto) req.isDefault = false
        const rs = await apiBankAccount.addBankAccount(req)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.addBankAccountsSuccess',
        })
        if (req.isAuto) {
          emit('verify-bank-account', rs)
        } else {
          emit('fetch-bank-account')
        }
        this.$bvModal.hide('modal-add-bank-accounts')
      } catch (error) {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.agency.addBankAccountsError',
        })
      } finally {
        loading.value = false
        this.$bvModal.hide('modal-api-loading')
      }
    }
    const bankListOptionsDefault = computed(() => store.getters['userStore/getListBank'])

    function resetBankListOptions() { bankListOptions.value = cloneDeep(bankListOptionsDefault.value) }

    function openBankListHandle() {
      if (!bankListOptions.value) resetBankListOptions()
    }

    const searchBankListHandle = debounce(search => {
      if (search) {
        bankListOptions.value = bankListOptionsDefault.value.filter(item => item.code.toLowerCase() === search.toLowerCase() || item.shortName.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
      } else if (search === '') {
        resetBankListOptions()
      }
    }, 300)
    const searchBankHolder = debounce(async search => {
      if (search && bankSelected.value && bankSelected.value.isAuto) {
        const { data } = await apiBankAccount.getBankHolder(bankSelected.value.gateway, bankAccountToAdd.value.bankAccountNo)
        bankAccountToAdd.value.bankAccountName = data.bankHolder
      }
    }, 1000)

    watch(() => bankAccountToAdd.value.bankAccountNo, newVal => {
      searchBankHolder(newVal)
    })
    return {
      refFormObserver,
      getValidationState,
      bankAccountToAdd,
      resetBankAccountToAdd,
      handleAdd,
      removeAccentsUpperCaseFormatter,
      showHandle,
      bankListOptions,
      openBankListHandle,
      searchBankListHandle,
      searchBankHolder,
      selectedBank,
      bankListOptionsDefault,
      bankSelected,
      formatCurrency,
    }
  },
}
</script>
