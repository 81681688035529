<template>
  <ValidationObserver
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <BModal
      id="modal-edit-bank-accounts"
      :title="$t('agency.bankAccountsList.editBankAccount')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{ cancel }">
        <BButton
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('agency.bankAccountsList.cancel') }}
        </BButton>

        <BButton
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="invalid"
          @click="handleEdit"
        >
          <div class="d-flex-center px-25">
            {{ $t('agency.bankAccountsList.edit') }}
          </div>
        </BButton>
      </template>

      <div>
        <!-- ANCHOR bankCode -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('agency.bankAccountsList.bankCode')"
          rules="required"
        >
          <BFormGroup
            label-for="bankCode"
          >
            <template #label>
              <div class="text-nowrap">
                {{ $t('agency.bankAccountsList.bankCode') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <v-select
              id="bankCode"
              v-model="dataToEdit.bankCode"
              :state="getValidationState(validationContext) === false ? false : null"
              label="key"
              :options="bankListOptions"
              :filterable="false"
              searchable
              :reduce="val => val.key"
              :placeholder="$t('agency.bankAccountsList.ph.bankCode')"
              :clearable="false"
              @open="openBankListHandle"
              @search="searchBankListHandle"
            >
              <template #option="data">
                <div class="d-flex-center justify-content-start">
                  <BImg
                    :src="data.value ? data.value.logo : findBank(data.key).value.logo"
                    width="50"
                  />
                  <span>
                    {{ `${data.value ? data.value.shortName : findBank(data.key).value.shortName} (${data.value ? data.value.code : findBank(data.key).value.code}) - ${data.value ? data.value.name : findBank(data.key).value.name}` }}
                  </span>
                </div>
              </template>

              <template #selected-option="data">
                <div class="d-flex-center justify-content-start">
                  <BImg
                    :src="data.value ? data.value.logo : findBank(data.key).value.logo"
                    width="50"
                  />
                  <span>
                    {{ `${data.value ? data.value.shortName : findBank(data.key).value.shortName} (${data.value ? data.value.code : findBank(data.key).value.code}) - ${data.value ? data.value.name : findBank(data.key).value.name}` }}
                  </span>
                </div>
              </template>

              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <div>
        <!-- ANCHOR Branch -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('agency.bankAccountsList.branch')"
          rules="required"
        >
          <BFormGroup
            label-for="branch"
          >
            <template #label>
              <div class="text-nowrap">
                {{ $t('agency.bankAccountsList.branch') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="branch"
              v-model="dataToEdit.branch"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('agency.bankAccountsList.ph.branch')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <div>
        <!-- ANCHOR bankAccountNo -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('agency.bankAccountsList.bankAccountNo')"
          rules="required"
        >
          <BFormGroup
            label-for="bankAccountNo"
          >
            <template #label>
              <div class="text-nowrap">
                {{ $t('agency.bankAccountsList.bankAccountNo') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="bankAccountNo"
              v-model.trim="dataToEdit.bankAccountNo"
              :state="getValidationState(validationContext) === false ? false : null"
              :placeholder="$t('agency.bankAccountsList.ph.bankAccountNo')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <div>
        <!-- ANCHOR bankAccountName -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('agency.bankAccountsList.bankAccountName')"
          rules="required"
        >
          <BFormGroup
            label-for="bankAccountName"
          >
            <template #label>
              <div class="text-nowrap">
                {{ $t('agency.bankAccountsList.bankAccountName') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <BFormInput
              id="bankAccountName"
              v-model="dataToEdit.bankAccountName"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              :formatter="removeAccentsUpperCaseFormatter"
              :placeholder="$t('agency.bankAccountsList.ph.bankAccountName')"
            />
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>

      <div
        id="is-default-div"
        class="w-100"
      >
        <!-- ANCHOR isDefault -->
        <ValidationProvider
          #default="validationContext"
          :name="$t('agency.bankAccountsList.isDefault')"
          rules="required"
        >
          <BFormGroup
            label-for="isDefault"
          >
            <BFormCheckbox
              id="isDefault"
              v-model="dataToEdit.isDefault"
              :state="getValidationState(validationContext) === false ? false : null"
              lazy-formatter
              switch
              :placeholder="$t('agency.bankAccountsList.isDefault')"
              :disabled="bankAccountsList[bankAccountIndex].isDefault"
            >
              <div class="d-flex-center justify-content-between">
                <div class="text-nowrap">
                  {{ $t('agency.bankAccountsList.isDefault') }}
                </div>
                <em v-if="bankAccountsList[bankAccountIndex].isDefault">Nếu muốn huỷ bỏ mặc định, hãy chọn một tài khoản ngân hàng khác làm mặc định.</em>
              </div>
            </BFormCheckbox>
            <BFormInvalidFeedback>
              {{ validationContext.errors[0] }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </ValidationProvider>
      </div>
    </BModal>
  </ValidationObserver>
</template>
<script>
import {
  BModal, BButton, BFormInvalidFeedback, BFormInput, BFormGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, computed } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import vSelect from 'vue-select'

import { apiAgencies } from '@/api'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

import useBankAccounts from './useBankAccounts'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BImg,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    agencyId: {
      type: Number,
      default: null,
    },
    bankAccountIndex: {
      type: Number,
      required: true,
    },
    bankAccountsList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()

    const dataToEdit = ref(cloneDeep(props.bankAccountsList[props.bankAccountIndex]))

    const resetBankAccountDataToEdit = () => {
      dataToEdit.value = cloneDeep(props.bankAccountsList[props.bankAccountIndex])
    }
    const { refFormObserver, getValidationState } = formValidation(resetBankAccountDataToEdit)
    const loading = ref(false)

    watch(() => props.bankAccountsList[props.bankAccountIndex], val => {
      dataToEdit.value = cloneDeep(val)
    }, { deep: true })

    function showHandle() {
      resetBankAccountDataToEdit()
    }

    function handleEdit() {
      loading.value = true

      const payload = [...props.bankAccountsList]

      payload[props.bankAccountIndex] = dataToEdit.value

      apiAgencies.updateBankAccounts(props.agencyId, {
        bankAccounts: payload,
      })
        .then(() => {
          emit('fetch-bank-accounts-list')
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.settingAccount.editBankAccountSuccess',
          })
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.settingAccount.editBankAccountError',
          })
        })
        .finally(() => {
          this.$bvModal.hide('modal-edit-bank-accounts')
          loading.value = false
        })
    }

    const bankListOptionsDefault = computed(() => store.getters['globalConfig/getBankList'])
    const bankListOptions = ref()
    function resetBankListOptions() { bankListOptions.value = cloneDeep(bankListOptionsDefault.value) }

    function openBankListHandle() {
      if (!bankListOptions.value) resetBankListOptions()
    }

    const searchBankListHandle = debounce(search => {
      if (search) {
        bankListOptions.value = bankListOptionsDefault.value.filter(item => item.key.toLowerCase() === search.toLowerCase() || item.value.shortName.toLowerCase().includes(search.toLowerCase()) || item.value.short_name.toLowerCase().includes(search.toLowerCase()) || item.value.name.toLowerCase().includes(search.toLowerCase()))
      } else if (search === '') {
        resetBankListOptions()
      }
    }, 300)

    const { findBank } = useBankAccounts()
    return {
      dataToEdit,
      handleEdit,

      refFormObserver,
      getValidationState,
      removeAccentsUpperCaseFormatter,
      showHandle,

      bankListOptions,
      openBankListHandle,
      searchBankListHandle,
      findBank,
    }
  },
}
</script>

<style lang="scss" scoped>
#is-default-div ::v-deep {
    .custom-control-label {
      width: 100%;
      color: black;
    }
  }
</style>
